<template>
  <div ref="container" class="scale-to-fit-container text-center">
    <div ref="textToScale" class="pb-3 d-inline-block">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScaleToFitContainer",
  mounted() {
    this.$nextTick().then(this.setScale);
    //this.$refs.textToScale.getElementsByClassName('exampleBox').forEach(el => el.addEventListener('load', this.setHeight()));

    //window.setTimeout(this.setScale, 300); //Safari fix
    window.addEventListener('resize', this.setScale)
  },
  methods: {
    setScale() {
      console.log('sets scale')
      this.$refs.textToScale.style.transform = 'scale(' + (this.$refs.container.clientWidth / (this.$refs.textToScale.clientWidth)) + ')';
      this.$refs.container.style.lineHeight = this.$refs.textToScale.children[0].getBoundingClientRect().height + 'px';

      //checking if text goes beyond Block after first scaling
      let textSpans = this.$refs.textToScale.children;
      let textWidth = 0;
      textSpans.forEach(el => {
        textWidth += el.getBoundingClientRect().width
      })
      if (textWidth > (this.$refs.container.clientWidth + 10)) {
        //rescaling text if still bigger than block - dependent on width of all spans within this line
        this.$refs.textToScale.style.transform = 'scale(' + (this.$refs.container.clientWidth / textWidth) + ')';//, translateX(-' + difference + 'px)';
        console.log('resets scale')
        //checking space difference between first Word of line and textblock left border (with padding)
          let firstSpanPos = textSpans[0].offsetLeft;
          let divPos = this.$refs.container.parentElement.offsetLeft;
          let difference = (firstSpanPos - divPos)/2;
          this.$refs.container.parentElement.style.left = difference + 'px';
          this.$refs.container.style.lineHeight = this.$refs.textToScale.children[0].getBoundingClientRect().height + 'px';
      }
    }
  }
}
</script>

<style>

</style>
