<template>
  <b-row class="justify-content-center">
    <b-col v-for="widget in widgets" cols="auto" :key="widget.widgetId">
      <button class="typeformButtons"
              :class="{active: widgetId === widget.widgetId }"
              @click="widgetId = widget.widgetId"
      >
        <span class="small">für</span> <span class="big">{{ widget.name }}</span>
      </button>
    </b-col>
    <b-col v-if="widgetId" cols="12" ref="widgetContainer">
      <TypeformWidget v-bind:widgetId="widgetId"></TypeformWidget>
    </b-col>
    <b-row class="footerContent justify-content-between">
      <b-col cols="12" sm="5">
        <span v-for="link in footerContent[0].linkLeft" :key="link.id"><a class="footerNavigation" :href="link.link" target="_blank">{{link.text}}</a></span>
      </b-col>
      <b-col cols="12" sm="5" class="trademark">
        <span>{{footerContent[1].textRight}}</span>
      </b-col>
    </b-row>
  </b-row>
</template>

<script>
import TypeformWidget from "@/components/TypeformWidget";

export default {
  name: "TypeformFooter",
  components: {
    TypeformWidget
  },
  props: {
    widgets: Array,
    footerContent: Array
  },
  data() {
    return {
      widgetId: "",
    }
  },
  created() {
    this.widgetId = this.widgets[0].widgetId;
  }
}
</script>

<style scoped>

</style>
