<template>
  <div id="app">
    <HeaderJC :logo="logo"/>
    <ScrollEventListener v-show="false" @scroll="scrollChat" :element-class="elementClass1"/>
    <ChatExample :pictures="profilePictures" :qrImg="qrCode" :messages="messages" :scrolledByY="isScrolledToChatByY"/>
    <ScrollEventListener v-show="false" @scroll="scrollTextblock" :element-class="elementClass2"/>
    <ColorChangeTextblock :texts="textBlockSentences" :scrolledByY="isScrolledToTextblockByY"/>
    <DescriptionPitch :mannequin="mannequin" :title="title" :pitch="pitch"/>
    <div class="arrowDown"><img src="../src/assets/polygon.svg" alt=""/></div>
    <TypeformFooter :widgets="typeFormWidgets" :footer-content="footerContent"/>
  </div>
</template>

<script>
import ChatExample from "@/components/ChatExample";
import ColorChangeTextblock from "@/components/ColorChangeTextblock";
import DescriptionPitch from "@/components/DescriptionPitch";
import TypeformFooter from "@/components/TypeformFooter";
import HeaderJC from "@/components/HeaderJC";
import ScrollEventListener from "@/components/ScrollEventListener";

export default {
  name: 'App',
  components: {
    ScrollEventListener,
    HeaderJC,
    ChatExample,
    ColorChangeTextblock,
    DescriptionPitch,
    TypeformFooter
  },
  data(){
    return {
      logo: 'logoHeader.svg',

      elementClass1: 'chatExample',
      isScrolledToChatByY: 0,
      profilePictures: [
        {picture: "user.png"},
        {picture: "james.png"}
      ],
      qrCode: [
        {picture: "qr-test-code.png"}
      ],
      messages: [
        {fromUser: [
            {textKey: 0, text: 'Ich brauche Blumen für unseren Hochzeitstag.'},
        ]},
        {fromJames: [
          {textKey: 0, text: 'Alles klar, ich kümmere mich darum.'},
          {textKey: 1, text: 'Deine Blumen sind an Deinem Parkplatz hinterlegt, du kannst sie mit diesem QR Code aus der Box holen.'},
          {textKey: 2, text: 'QR_TEST_CODE'}
        ]}
      ],

      elementClass2: 'colorChangeTextblock',
      isScrolledToTextblockByY: 0,
      textBlockSentences:[
        {text: 'Obst und Gemüse vom Biohof'},
        {text: 'Drogerieartikel'},
        {text: 'Tinte für den Drucker'},
        {text: 'Auto aussaugen lassen'},
        {text: 'Reifen wechseln'},
        {text: 'PC reparieren'},
        {text: 'Termine reservieren'},
        {text: 'Ein Brot vom Bäcker'},
        {text: 'Handy Display reparieren lassen'},
        {text: 'Druckerpapier bestellen'},
        {text: 'Zigaretten bestellen'},
        {text: 'Termin beim Friseur'},
        {text: 'Damenbinden bestellen'},
        {text: 'Computer Adapter'},
        {text: 'Grillfleisch vom Metzger'},
        {text: 'USB Ladegerät'}
      ],

      mannequin: 'jcooper.svg',
      title: 'Wunschkonzert:',
      pitch: 'Es ist die Zeit der Wünsche für unsere Kunden und zukünftigen Anwender, aber auch für unsere Kooperationspartner, den Einzelhandels- oder Dienstleistungsunternehmen.',

      typeFormWidgets:[
        {
          name: "Nutzer",
          widgetId: 'C4paG8gu'
        },
        {
          name: "Kooperationspartner",
          widgetId: 'fNGOfvpL'
        }
      ],

      footerContent:[
        {linkLeft: [
              {text: "Impressum", link: "https://pixelstein.de/impressum"},
              {text: "Datenschutz", link: "https://pixelstein.de/datenschutz"}
        ]},
        {textRight: "James Cooper ist eine Marke der Pixelstein GmbH & Co. KG"}
      ]
    }
  },
  methods: {
    scrollTextblock(value) {
      this.isScrolledToTextblockByY = value;
    },
    scrollChat(value) {
      this.isScrolledToChatByY = value;
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/main";
</style>
