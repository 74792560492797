<template>
  <b-row class="headerJC justify-content-center align-items-center">
    <b-col cols="8">
      <b-row class="align-items-center">
        <b-col class="text-center">
          <img class="img-fluid text-center" v-bind:src="require('../assets/' + logo)" alt="James Cooper Logo">
        </b-col>
      </b-row>
      <b-row class="align-items-center">
        <b-col>
          <button class="scrolldownHeaderButton py-5" @click="scrollDown"></button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill';
export default {
  name: "HeaderJC",
  props: {
    logo: String
  },
  methods: {
    scrollDown() {
      smoothscroll.polyfill();
      document.querySelector('.chatExample').scrollIntoView({behavior: "smooth"})
    }
  }
}
</script>

<style scoped>

</style>