<template>
  <b-row class="descriptionPitch justify-content-center align-items-center">
    <b-col cols="11" lg="10" xl="7" class="d-flex align-items-center">
      <div class="mb-5">
        <img v-bind:src="require('../assets/' + mannequin)" alt="User" class="img-responsive">
        <h1>{{ title }}</h1>
        <p>{{ pitch }}</p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "DescriptionPitch",
  props: {
    mannequin: String,
    title: String,
    pitch: String
  }
}
</script>

<style scoped>

</style>