<template>
  <div class="typeform-widget"></div>
</template>

<script>
import {createWidget} from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'

export default {
  name: "TypeformWidget",
  props: {
    widgetId: String
  },
  watch: {
    widgetId(nv, ov) {
      if (nv && nv !== ov) { // Wenn sich die widgetId ändert

        if (this.unmount) { // Wenn bereits ein widget erstellt wurde
          this.unmount() // entferne das alte widget
        }

        this.$nextTick(this.initWidget) // gib unmount eine tick zeit um sicher zu gehen, dass das Widget abgebaut ist & erstelle das neue widget

      }
    }
  },
  mounted() {
    this.initWidget() // erstelle das Widget wenn die Komponente gemountet ist
  },
  methods: {
    initWidget() {
      const {unmount} = createWidget(this.widgetId, { // Widget erstellen wie auf github direkt unter installation beschrieben
        container: this.$el,
        height: window.innerHeight,
        width: this.$el.clientWidth
      })
      this.unmount = unmount // unmount function des grade erstellten Widgets in der Komponente zwischenspeichern
    }
  }
}
</script>

<style scoped>

</style>
