<template>
  <div></div>
</template>
<script>
export default {
  name: "ScrollEventListener",
  data() {
    return {
      isScrolledToElementByY: 0
    }
  },
  props: {
    elementClass: String,
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    onScroll() {
      let elementViewY = document.getElementsByClassName(this.elementClass)[0].getBoundingClientRect().y;
      this.isScrolledToElementByY = elementViewY;
      this.$emit('scroll', this.isScrolledToElementByY)
    }
  }
}
</script>

<style scoped>

</style>