<template>
  <b-row class="chatExample justify-content-center">
    <b-col cols="8">
      <b-row class="userMsg">
        <b-col col lg="7" sm="12">
          <div v-for="message in messages[0].fromUser" :key="message.textKey">
            <transition name="message">
              <chat-message class="message-enter user">{{message.text}}</chat-message>
            </transition>
          </div>
          <div>
            <div class="userPicture">
              <img class="message-enter" ref="imgUser" v-bind:src="require('../assets/' + this.pictures[0].picture)" alt="User">
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="james">
        <b-col col lg="7" sm="12">
          <transition-group tag="span" name="message">
            <div v-for="message in messages[1].fromJames" :key="message.textKey" :ref="'jamesMessage' + message.textKey">
              <chat-message class="message-enter james" :fromJames="true">{{message.text}}</chat-message>
            </div>
          </transition-group>
          <div class="jamesPicture">
            <img class="message-enter james" ref="imgJames" v-bind:src="require('../assets/' + this.pictures[1].picture)" alt="James">
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import ChatMessage from "jamescooper-shared-vue-source/src/components/ChatMessage";
export default {
  name: "ChatExample",
  components: {ChatMessage},
  data() {
    return {
      chatMessages: [],
    }
  },
  props: {
    pictures: Array,
    qrImg: Array,
    messages: Array,
    scrolledByY: Number
  },
  watch: {
    scrolledByY: function () {
      //when scrolled to message position, start method
      for (let i = 0; i < this.chatMessages.length; i++) {
        let elementViewY = this.chatMessages[i].getBoundingClientRect().y - window.innerHeight/2
        if (elementViewY < 0) {
          this.showMessages(this.chatMessages[i]);
        }
      }
    }
  },
  mounted() {
    this.chatMessages = document.querySelectorAll('.chat-message');
    //if message is 'QR_TEST_CODE' replace it with image of QR-prop
    this.chatMessages.forEach(el => {
      if (el.firstChild.nodeValue === 'QR_TEST_CODE') {
        let qrTestImg = document.createElement("img");
        qrTestImg.src = require('../assets/' + this.qrImg[0].picture);
        qrTestImg.classList.add('qrImage')
        el.replaceChild(qrTestImg, el.firstChild);
      }
    })
  },
  methods: {
    showMessages(currentMessage) {

      //start animation for current message when scrolled down
      currentMessage.classList.remove('message-enter');
      currentMessage.classList.add('message-enter-active');

      //on showing first message, also show profile picture and adjust positioning
      let chatting = ['User', 'James']
      for (let n = 0; n < chatting.length; n++) {
        let msgSender = 'from' + chatting[n]
        let senderImg = 'img' + chatting[n]
        let profileImg = this.$refs[senderImg];
        let hiddenHeight = 0

        //determine which messages are from whom...
        let senderHiddenClass = 'message-enter'
        if (chatting[n] === 'James') {
          senderHiddenClass += ' james';
        } else {
          senderHiddenClass += ' user';
        }

        //...and get height of all hidden messages of one Sender
        let hiddenMsg = document.getElementsByClassName(senderHiddenClass);
        hiddenMsg.forEach(el => {
          hiddenHeight += el.offsetHeight + 50;
        })

        //adjust height of picture according to height of hidden messages
        profileImg.setAttribute("style", "bottom: " + hiddenHeight + "px");

        //show image together with first message
        if (currentMessage.firstChild.nodeValue === this.messages[n][msgSender][0].text) {
          profileImg.classList.remove('message-enter');
          profileImg.classList.add('message-enter-active')
        }
      }
    }
  }
}
</script>

<style scoped>

</style>