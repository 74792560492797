<template>
  <b-row class="colorChangeTextblock justify-content-center">
      <b-col cols="8" v-for="example in examples" :key="example.id">
        <ScaleToFitContainer>
          <span class="cchange" v-for="word in example.text" :key="word.id">{{word.word}}</span>
        </ScaleToFitContainer>
      </b-col>
  </b-row>
</template>

<script>
import ScaleToFitContainer from "@/components/ScaleToFitContainer";
export default {
  name: "ColorChangeTextblock",
  components: {ScaleToFitContainer},
  props: {
    texts: Array,
    scrolledByY: Number
  },
  data() {
    return {
      examples: [],
    }
  },
  created() {
    this.splitWords();
  },
  watch: {
    scrolledByY: function (newVal) {
      let windowHeight = window.innerHeight;
      let elementHeight = document.getElementsByClassName('colorChangeTextblock')[0].clientHeight;
      let currentViewY = document.getElementById('app').scrollTop

      //elements y position in relation to 'app'
      let elementsOffset = currentViewY + newVal;

      //Determines how many pixels can be scrolled for each word
      let scrollDistanceByWord = (elementHeight + windowHeight*0.6) / document.querySelectorAll('.cchange').length;

      //if textblock gets into view...
      if (currentViewY > (elementsOffset - (windowHeight*0.8))) {
        //... the word of index i will be highlighted based on difference between elements y position and current scroll y position
        let i = Math.round((currentViewY - (elementsOffset - (windowHeight*0.8))) / scrollDistanceByWord);
        this.changeColors(i);
      }
    }
  },
  methods: {
    splitWords() {
      let i = 0;
      // splits sentences parsed as props into an array of words
      this.texts.forEach((values) => {
        let words = [];
        let sentences = {text: ''}
        let sentence = values.text.split(' ')
        //gives words an id and arranges them back in an array for each sentence
        sentence.forEach((value) => {
          let wordWithId = {word: '', id: ''};
          wordWithId.word = value + " ";
          wordWithId.id = i
          words.push(wordWithId)
          i++
          sentences.text = words;
        })
        //parses the sentences to data of this component, from where it will be used inside template
        this.examples.push(sentences);
      })
    },
    //changes every word to its original color and highlights only the word of current index i
    changeColors(i) {
      let wordToChange = document.querySelectorAll('.cchange');
      wordToChange.forEach(word => {
        word.style.color = 'white';
      })
      if (i < wordToChange.length) {
        wordToChange[i].style.color = '#161616';
      } else {
        //if current index is higher then the number of words, it highlights the last word
        let n = wordToChange.length -1;
        wordToChange[n].style.color = '#161616';
      }
    }
  }
}
</script>

<style scoped>

</style>